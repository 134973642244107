.icon{
    width: 27vw;
    height: 27vw;
    margin: 4vw;
    margin-bottom: 5vw;
    padding: 3vw;
    border-radius: 30px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 5px #00000008);
}
.icon img{
    width: 27vw;
    height: 27vw;
}