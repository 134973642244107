@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@500&display=swap');

:root{
	--blue: linear-gradient(#338CF1,#0b60c1);
	--green: linear-gradient(#30D680,#25ca75);
	--yellow: linear-gradient(#ffd11a,#ffc42f);
	--red: linear-gradient(#F83340,#fd626c);
	--white: #F1F3F9;
	--black: #1E1F22;
	--black-shadow: #1e1f2244;
}