@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;800;900;1000&display=swap');


body{
	margin: 0;
	padding: 0;
    background-image: url(../../../public/background/bg-1.png);
    background-repeat:no-repeat;
	background-size: cover;
    background-attachment: fixed;
    background-position: center;
	font-family: 'Outfit', sans-serif;
}

.logo{
	height: 20vh;
	background-image: url(../../../public/icons/logo.png);
	background-repeat:no-repeat;
	background-size: cover;
    background-position: center;
}


.box-transport-method{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
    margin-top: 15vh;
	margin-left: 5vw;
	margin-right: 5vw;
    background-color: #ffffff8b;
    filter: drop-shadow(0px 0px 20px var(--black-shadow));
    backdrop-filter: blur(5px);
    border-radius: 30px;
}

.container{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.title-1{
	text-align: center;
	font-size: 40pt;
	margin-bottom: 10px;
}

.choice-stops{
	display: flex;
	width: 80vw;
	font-size: 10px;
	border-width: 12px;
	border-color: #ffd11a;
	background-color: #ffffff76;
	backdrop-filter: blur(5px);
	border-style: solid;
	border-radius: 30px;
	margin-left: auto;
	margin-right: auto;
	filter: drop-shadow(0px 0px 5px #00000038);

}

.choice-stops input{
	font-size: 20pt;
	width: 100%;
	height: 30px;
	border-radius: 30px;
	border: none;
	background-color: #ffffff00;
	padding: 10px;
	margin: 0;
	outline: inherit;
	font-weight: bold;
	color: #1E1F22;
}

.choice-stops svg{
	margin-left: 10px;
	margin-top: 5px;
	height: 40px;
	fill: #ffd11a;
}

.start-button{
	text-align: center;
	filter: drop-shadow(0px 0px 5px #00000038);
}

.start-button button{
	text-align: center;
	width: 80vw;
	font-weight: 700;
	font-size: 25pt;
	background: var(--yellow);
	color: var(--black);
	padding: 15px;
	border-radius: 20px;
	border-style: none;
	outline: inherit;
}
.distance{
	font-weight: 800;
	font-size: 3.5rem;
	text-align: center;	
}





.backArrow{
	height: 5rem;
	position: absolute;
	top: 10px;
	left: 10px;
}


/* Animation */

.truck-wrapper{
	height: 200px;
	width: 200px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translateX(-50%) translateY(-50%) scale(0.8);
	background: var(--white);
	animation:bg 0.5s linear infinite;
	border-radius: 100%;
	overflow:hidden;
}

.truck{
	height:110px;
	width:150px;
	position:absolute;
	
	bottom:48px;
	left: calc(50% + 10px);
	transform: translateX(-50%);
	
}

.truck > .glases{
	background: rgb(40,181,245);
	background: -moz-linear-gradient(-45deg, rgba(40,181,245,1) 0%, rgba(40,181,245,1) 50%, rgba(2,153,227,1) 52%, rgba(2,153,227,1) 100%);
	background: -webkit-linear-gradient(-45deg, rgba(40,181,245,1) 0%,rgba(40,181,245,1) 50%,rgba(2,153,227,1) 52%,rgba(2,153,227,1) 100%);
	background: linear-gradient(135deg, #514f4f 0%,#514f4f 50%,#333131 52%,#333131 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28b5f5', endColorstr='#0299e3',GradientType=1 );
	position:absolute;
	height:25px;
	width:143.9px;
	border:4px solid #ffd11a;
	border-bottom:none;
	top:35.5px;
	left:-19px;
	border-top-right-radius:6px;
	animation: updown-half 0.4s linear infinite;
}
.truck > .glases:after{
	content:'';
	display:block;
	background-color:#ffd11a;
	height:6px;
	width:3px;
	position:absolute;
	right:-6px;
	bottom:0px;
	border-radius:10px / 15px;
	border-bottom-right-radius:0px;
	border-bottom-left-radius:0px;
	border-top-left-radius:0px;
	
}

.truck > .glases:before{
	content:'';
	display:block;
	background-color:#FBD734;
	height:27px;
	width:3px;
	position:absolute;
	left:102px;
	bottom:0px;
/*   border-top-right-radius:4px; */
}

.truck > .bonet{
	background-color:#FBD734;
	position:absolute;
	width:153.8px;
	height:15px;
	top:64px;
	left:-19px;
	z-index:-1;
	animation: updown 0.4s linear infinite;
}

.truck > .bonet:after{
	content:'';
	display:block;
	background: rgb(255,255,255);
	background: -moz-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 50%, rgba(225,225,225,1) 51%, rgba(246,246,246,1) 100%);
	background: -webkit-linear-gradient(-45deg, rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(225,225,225,1) 51%,rgba(246,246,246,1) 100%);
	background: linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(225,225,225,1) 51%,rgba(246,246,246,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=1 );
	height:10px;
	width:6px;
	position:absolute;
	right:0px;
	bottom:2px;
	border-top-left-radius:4px;
	
}

.truck > .base{
	position:absolute;
	background-color:#445A64;
	width:134px;
	height:15px;
	border-top-right-radius:10px;
	top:70px;
	left:-15px;
	animation: updown 0.4s linear infinite;
}

.truck > .base:before{
	content:'';
	display:block;
	background-color:#E54A18;
	height:20px;
	width:5px;
	position:absolute;
	left:-4px;
	bottom:0px;
	border-bottom-left-radius:4px;
}

.truck > .base:after{
	content:'';
	display:block;
	background-color:#445A64;
	height:10px;
	width:20px;
	position:absolute;
	right:-16px;
	bottom:0px;
	border-bottom-right-radius:4px;
	z-index:-1;
}

.truck > .base-aux{
	width:3px;
	height:26px;
	background-color:#FBD734;
	position:absolute;
	top:38px;
	left:25px;
/*   border-bottom-right-radius:4px; */
	animation: updown-half 0.4s linear infinite;
}
.truck > .wheel-back{
	left:20px
}

.truck > .wheel-front{
	left:95px;
}

.truck > .wheel-back,.truck > .wheel-front{
	
	border-radius:100%;
	position:absolute;
background: rgb(84,110,122);
background: -moz-linear-gradient(-45deg, rgba(84,110,122,1) 0%, rgba(84,110,122,1) 49%, rgba(68,90,100,1) 52%, rgba(68,90,100,1) 100%);
background: -webkit-linear-gradient(-45deg, rgba(84,110,122,1) 0%,rgba(84,110,122,1) 49%,rgba(68,90,100,1) 52%,rgba(68,90,100,1) 100%);
background: linear-gradient(135deg, rgba(84,110,122,1) 0%,rgba(84,110,122,1) 49%,rgba(68,90,100,1) 52%,rgba(68,90,100,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#546e7a', endColorstr='#445a64',GradientType=1 );
	top:75px;
	height:22px;
	width:22px;
animation:spin 0.6s linear infinite;
}

.truck > .wheel-back:before,.truck > .wheel-front:before{
	content:'';
	border-radius:100%;
	left:5px;
	top:5px;
	position:absolute;
	background: rgb(175,189,195);
background: -moz-linear-gradient(-45deg, rgba(175,189,195,1) 0%, rgba(175,189,195,1) 50%, rgba(143,163,173,1) 51%, rgba(143,163,173,1) 100%);
background: -webkit-linear-gradient(-45deg, rgba(175,189,195,1) 0%,rgba(175,189,195,1) 50%,rgba(143,163,173,1) 51%,rgba(143,163,173,1) 100%);
background: linear-gradient(135deg, rgba(175,189,195,1) 0%,rgba(175,189,195,1) 50%,rgba(143,163,173,1) 51%,rgba(143,163,173,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#afbdc3', endColorstr='#8fa3ad',GradientType=1 );
	height:12px;
	width:12px; 
}

@keyframes spin {
	50%{
	top:76px;
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes container {
	
	30%{
	transform:rotate(1deg);
	}
	50%{
	top:11px;
	}
	
	70%{
	top:10px;
	transform:rotate(-1deg);
	}
}

.truck > .smoke{
	position:absolute;
	background-color:#AFBDC3;
	border-radius:100%;
	width:8px;
	height:8px;
	top:90px;
	left:6px;
	animation: fade 0.4s linear infinite;
	opacity:0;
}

.truck > .smoke:after{
	content:'';
	position:absolute;
	background-color:RGB(143,163,173);
	border-radius:100%;
	width:6px;
	height:6px;
	top:-4px;
	left:4px;
}

.truck > .smoke:before{
	content:'';
	position:absolute;
	background-color:RGB(143,163,173);
	border-radius:100%;
	width:4px;
	height:4px;
	top:-2px;
	left:0px;
}

@keyframes fade {
	
	30%{
	opacity:0.3;
	left:7px;
	}
	50%{
	opacity:0.5;
	left:6px;
	}
	
	70%{
	opacity:0.1;
	left:4px;
	}
	
	90%{
	opacity:0.4;
	left:2px;
	}
}

@keyframes bg {
	from{
	background-position-x:0px;
	}
	to{
	background-position-x:-400px;
	}
}

@keyframes updown {
	50%{
	transform:translateY(-20%);
	}
	
	70%{
	transform:translateY(-10%);
	}
}

@keyframes updown-half{
	50%{
	transform:translateY(-10%);
	}
	
	70%{
	transform:translateY(-5%);
	}
}